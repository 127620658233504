const otherFields = [
  {label: "Zephyr Monitors Updates", dataField: "monitorupdates"},
  {label: "Bot Announcements Feeds", dataField: "bot_announcement_feed"},
  {label: "Hellstar Announcements Feeds", dataField: "announcements_hellstar"},
  {label: "Valor AIO Checkout Feed", dataField: "valorfeed"},
  {label: "Bot Update Feeds", dataField: "botupdatefeeds"},
  {label: "Kylin Checkout Feed", dataField: "kylin"},
  {label: "Alpine AIO Checkout Feed", dataField: "alpinefeed"},
    {label: "NSB Checkout Feed", dataField: "nsbfeed"},
    {label: "Hayha Checkout Feed", dataField: "hayhafeed"},
  {label: "CyberAIO Checkout Feed", dataField: "cyberaiofeed"},
  {label: "Mek AIO Shopify Checkout Feed", dataField: "mekaioshopify"},
  {label: "Mek AIO Supreme Checkout Feed", dataField: "mekaiosupreme"},
    {label: "Mek AIO Footsites Checkout Feed", dataField: "mekaiofootsites"},
    {label: "Mek AIO Nike/Snkrs Checkout Feed", dataField: "mekaionike"},
      {label: "Mek AIO FNL/JD Checkout Feed", dataField: "mekaiofnl"},

  {label: "TSB Checkout Feed", dataField: "tsbfeed"},
  {label: "Stellar Checkout Feed", dataField: "stellarfeed"},


  {label: "Magnet AIO Ssense Checkout Feed", dataField: "magnetaiossense"},
  {label: "Magnet AIO Footsites Checkout Feed", dataField: "magnetaiofootsites"},
  {label: "Refract Checkout Feed", dataField: "refractfeed"},
  {label: "Prism AIO Checkout Feed", dataField: "prismfeed"},
//  {label: "Polaris Checkout Feed ", dataField: "polarisfeed"},
 
  {label: "Zephyr AIO Checkout Feed", dataField: "zephyraiofeed"},

];

export default otherFields;
